#select-user-modal {
  top: 50px;
}

.dialog-overlay {
  overflow: auto;
}

.user-list-delete-btn {
  background-color: transparent;
  border: unset;
}
